<template>
  <div class="container">
    <el-menu
      :default-active="defaultActive"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      :collapse-transition="false"
      @select="menuSelect"
    >
      <template v-for="item in menuList">
        <el-sub-menu
          v-if="item.children && item.children.length"
          :index="item.menuPath"
        >
          <template #title>
            <img width="14" :src="item.menuIcon" alt="" />
            <span>{{ item.menuName }}</span>
          </template>
          <template v-for="childItem in item.children">
            <el-menu-item-group
              v-if="childItem.group"
              :title="childItem.groupTitle"
            >
              <template v-for="subChildItem in childItem.items">
                <el-menu-item :index="subChildItem.menuPath">
                  <img width="14" :src="subChildItem.menuIcon" alt="" />
                  <!-- <el-icon><location /></el-icon> -->
                  {{ subChildItem.name }}
                </el-menu-item>
              </template>
            </el-menu-item-group>
            <el-sub-menu
              v-else-if="childItem.children && childItem.children.length"
              :index="childItem.menuPath"
            >
              <template #title>
                <img width="14" :src="childItem.menuIcon" alt="" />
                <span>{{ childItem.menuName }}</span>
              </template>
              <template v-for="subChildItem in childItem.children">
                <el-menu-item :index="subChildItem.menuPath">
                  <img width="14" :src="subChildItem.menuIcon" alt="" />
                  {{ subChildItem.menuName }}
                </el-menu-item>
              </template>
            </el-sub-menu>
            <el-menu-item v-else :index="childItem.menuPath">
              <img width="14" :src="childItem.menuIcon" alt="" />
              {{ childItem.menuName }}
            </el-menu-item>
          </template>
        </el-sub-menu>
        <el-menu-item v-else :index="item.menuPath">
          <img width="14" :src="item.menuIcon" alt="" />
          <template #title>{{ item.menuName }}</template>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script setup>
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from "@element-plus/icons-vue";
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

const route = useRoute();
const router = useRouter();

const { isCollapse } = defineProps({
  isCollapse: {
    type: Boolean,
    default: false,
  },
});

const menuList = ref([]);

const defaultActive = ref("");

onMounted(() => {
  const menuData = localStorage.getItem("menuTree");
  if (menuData) menuList.value = JSON.parse(menuData);

  const activeMenu = localStorage.getItem("activeMenu");
  if (activeMenu) {
    defaultActive.value = activeMenu;
  } else {
    defaultActive.value = menuList.value[0].menuPath;
  }
});

const menuSelect = (path) => {
  router.push(path);
  let foundId = null;
  function findIdInMenuList(list) {
    for (const item of list) {
      if (item.menuPath === path) {
        foundId = item.id;
        break;
      }
      if (item.children && item.children.length > 0) {
        const childId = findIdInMenuList(item.children);
        if (childId) {
          foundId = childId;
          break;
        }
      }
    }
    return foundId;
  }
  const id = findIdInMenuList(menuList.value);
  localStorage.setItem("activeMenu", path);
  localStorage.setItem("activeMenuId", id);
};
</script>

<style lang="scss" scoped>
:deep(.el-menu) {
  border: none;
}

img {
  margin-right: 8px;
}

.container {
  padding-bottom: 20px;
}

.line {
  width: 3px;
  height: 20px;
  background-color: #06b0b2;
  margin-right: 14px;
}
</style>
