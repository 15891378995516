import Layout from "@/layout/index.vue";
// 商业道具系统
const accessorySystemRouter = [
  {
    path: "/accessorySystem",
    component: Layout,
    redirect: "accessorySystem",
    hidden: true,
    name: "accessorySystem",
    meta: { title: "商业道具管理", icon: "safety" },
    children: [
      {
        path: "/accessoryProject",
        component: () =>
          import("@/views/accessorySystem/accessoryProject/index.vue"),
        name: "accessoryProject",
        meta: { title: "道具立项", icon: "" },
      },
    ],
  },
];
export default accessorySystemRouter;
