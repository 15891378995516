import Layout from "@/layout/index.vue";
// 家具家居管理
const furnitureSystemRouter = [
  {
    path: "/furnitureSystem",
    component: Layout,
    redirect: "furnitureSystem",
    hidden: true,
    name: "furnitureSystem",
    meta: { title: "家具家居管理", icon: "" },
    children: [
      {
        path: "/furnitureProjectProposal",
        component: () =>
          import("@/views/furnitureSystem/furnitureProjectProposal/index.vue"),
        name: "furnitureProjectProposal",
        meta: { title: "家具立项", icon: "" },
      },
    ],
  },
];
export default furnitureSystemRouter;
