<template>
  <div>
    <el-dialog
      v-model="globalDialogProps.show"
      width="500"
      draggable
      align-center
    >
      <div
        class="title"
        :style="{
          textAlign: globalDialogProps.textAlign
            ? globalDialogProps.textAlign
            : 'center',
          paddingLeft: globalDialogProps.textAlign === 'left' ? '30px' : '0px',
        }"
      >
        {{ globalDialogProps.title }}
      </div>
      <div class="content">
        <div class="tips">{{ globalDialogProps.tips }}</div>
        <div class="tip_text">{{ globalDialogProps.tipsText }}</div>
      </div>

      <div class="btn_box">
        <el-button
          class="select_btn"
          size="large"
          type="primary"
          @click="confirm"
          style="width: 100%"
        >
          {{ globalDialogProps.btnText }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";

const { globalDialogProps } = defineProps({
  globalDialogProps: Object,
});

const emits = defineEmits(["dialogConfirm"]);

const confirm = () => {
  emits("dialogConfirm");
};
</script>
<style lang="scss" scoped>
:deep(.el-dialog) {
  position: fixed;
  top: 50%;
  right: 20%;
  transform: translate(0, -50%);
  width: 440px;
  height: 500px;
  padding: 30px;
}
.title {
  padding-top: 20px;
  font-family: Alibaba PuHuiTi 2;
  font-size: 36px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;

  font-feature-settings: "kern" on;
  color: #9e9e9e;
}

.content {
  padding: 0 40px;
  .tips {
    font-size: 14px;
    color: #06b0b2;
    margin-top: 32px;
    margin-bottom: 20px;
  }

  .tip_text {
    font-size: 14px;
    margin-bottom: 140px;
    color: #9e9e9e;
  }
}

.btn_box {
  position: absolute;
  bottom: 50px;
  width: calc(100% - 60px);
  padding: 0 40px;
}
</style>
