import Layout from "@/layout/index.vue";
// 商业空间系统
const commercialSystemRouter = [
  {
    path: "/commercialSystem",
    component: Layout,
    redirect: "commercialSystem",
    hidden: true,
    name: "commercialSystem",
    meta: { title: "商业空间系统", icon: "" },
    children: [
      {
        path: "/projectInitiation",
        component: () =>
          import("@/views/commercialSystem/projectInitiation/index.vue"),
        name: "projectInitiation",
        meta: { title: "工程立项", icon: "" },
      },
    ],
  },
];
export default commercialSystemRouter;
