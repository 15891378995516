import Layout from "@/layout/index.vue";
// 办公管理系统
const officeManageRouter = [
  {
    path: "/officeManage",
    component: Layout,
    redirect: "officeManage",
    hidden: true,
    name: "officeManage",
    meta: { title: "办公管理系统", icon: "" },
    children: [
      {
        path: "/ProcessBinding",
        component: () =>
          import("@/views/officeManage/approvalManage/index.vue"),
        name: "ProcessBinding",
        meta: { title: "流程设置", icon: "" },
      },
    ],
  },
];
export default officeManageRouter;
