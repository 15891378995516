import { createRouter, createWebHistory } from "vue-router";
import baseRouter from "./modules/base";
import backOfficeRouter from "./modules/backOfficeRouter";
import personnelMattersRouter from "./modules/personnelMattersRouter";
import financialManageRouter from "./modules/financialManage";
import userCenterRouter from "./modules/userCenter";
import accessorySystemRouter from "./modules/accessorySystem";
import commercialSystemRouter from "./modules/commercialSystem";
import decorationWorksSystemRouter from "./modules/decorationWorksSystem";
import furnitureSystemRouter from "./modules/furnitureSystem";
import officeManageRouter from "./modules/officeManage";
import partnerManageRouter from "./modules/partnerManage";
import procureManageRouter from "./modules/procureManage";
import salesManageRouter from "./modules/salesManage";
import Cookie from "@/uitls/cookie";

const routes = [
  ...baseRouter,
  ...backOfficeRouter,
  ...personnelMattersRouter,
  ...userCenterRouter,
  ...financialManageRouter,
  ...accessorySystemRouter,
  ...commercialSystemRouter,
  ...decorationWorksSystemRouter,
  ...furnitureSystemRouter,
  ...officeManageRouter,
  ...partnerManageRouter,
  ...procureManageRouter,
  ...salesManageRouter,
];

// const routers = [...routes];

const router = createRouter({
  mode: "hash",
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes],
});

const whiteList = [
  "/login",
  "/codeLogin",
  "/wechatLogin",
  "/register",
  "/forgotPassword",
];
router.beforeEach((to, from, next) => {
  const token = Cookie.get("token");
  if (whiteList.includes(to.path)) {
    next();
  } else if (token) {
    next();
  } else {
    next("/login");
    // next();
  }

  if (to.path === "/" && from.path === "/register") {
    sessionStorage.setItem("isRegister", true);
  }
});

export default router;
