import Layout from "@/layout/index.vue";
// 个人中心
const userCenterRouter = [
  {
    path: "/",
    component: Layout,
    hidden: true,
    name: "personalCenter",
    meta: { title: "个人中心", icon: "safety" },
    children: [
      {
        path: "/basicInfo",
        component: () => import("@/views/personalCenter/index.vue"),
        meta: { title: "个人中心-基本信息", icon: "home" },
      },
    ],
  },
];
export default userCenterRouter;
