import Layout from "@/layout/index.vue";
import JoinTeamROuter from "@/router/modules/joinTeam";

const baseRouter = [
  {
    path: "/",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/",
        component: () => import("@/views/home/index"),
        meta: { title: "我的门户", icon: "home" },
      },
      {
        path: "/empty",
        component: () => import("@/views/empty/index.vue"),
        meta: { title: "柴404", icon: "gateway" },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index"),
    hidden: true,
    children: [
      {
        path: "/login",
        component: () => import("@/views/login/component/login"),
        meta: { title: "登录", icon: "login" },
      },
      {
        path: "/codeLogin",
        component: () => import("@/views/login/component/codeLogin"),
        meta: { title: "验证码登录", icon: "login" },
      },
      {
        path: "/wechatLogin",
        component: () => import("@/views/login/component/wechatLogin"),
        meta: { title: "微信登录", icon: "login" },
      },
      {
        path: "/register", // 注册
        component: () => import("@/views/login/component/register"),
        hidden: true,
      },
      {
        path: "/forgotPassword",
        component: () => import("@/views/login/component/forgotPassword"),
        hidden: true,
      },
    ],
  },
  ...JoinTeamROuter,
  // {
  //   path: "/auth-redirect", // 空白
  //   component: () => import("@/views/login/auth-redirect"),
  //   hidden: true,
  // },
  // {
  //   path: "/forgotpassword", // 忘记密码
  //   component: () => import("@/views/login/forgotpassword"),
  //   hidden: true,
  // },

  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true,
  },
  { path: "/:catchAll(.*)", redirect: "/empty", hidden: true },
];

export default baseRouter;
