import http from "./request";

//获取微信jsapi
export function getWxQRCodeApi() {
  return http.get("/api/admin/wechat/generateAWeChatQRCode");
}

//获取用户信息
export function getuserInfoApi() {
  return http.get("/api/admin/sysuser/getSysUser");
}

// 用户登录接口
export function loginApi(data) {
  return http({
    url: "/api/admin/login/user",
    method: "post",
    data,
  });
}

// 用户注册接口
export function registerApi(data) {
  return http({
    url: "/api/admin/sysuser/registerAccountInitial",
    method: "post",
    data,
  });
}

// 忘记密码
export function forgotPasswordApi(data) {
  return http({
    url: "/api/admin/sysuserdetail/retrievePasswordByUsername",
    method: "post",
    data,
  });
}

// 修改用户密码
export function editPasswordApi(data) {
  return http({
    url: "/api/admin/sysuserdetail/updatePassword",
    method: "post",
    data,
  });
}

// 获取验证码
export function getSmsCaptcha(data) {
  return http({
    url: "/api/admin/captcha/getSmsCaptcha",
    method: "get",
    params: {
      mobile: data,
    },
  });
}

// 退出登录
export function logoutApi() {
  return http({
    url: "/api/admin/login/logout",
    method: "post",
  });
}
