export const permission = {
  mounted(el, binding) {
    if (!permsJudge(binding.value)) {
      el.parentNode.removeChild(el);
    }
    function permsJudge(value) {
      let perms = JSON.parse(localStorage.getItem("permissionCodes")) || [];
      perms.push("default");
      for (let item of perms) {
        if (item === value) {
          return true;
        }
      }
      return false;
    }
  },
};
