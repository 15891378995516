import Layout from "@/layout/index.vue";
// 平台后台 管理
const backOfficeRouter = [
  {
    path: "/backstageManage",
    component: Layout,
    redirect: "backstageManage",
    hidden: true,
    name: "backstageManage",
    meta: { title: "后台管理系统", icon: "" },
    children: [
      {
        path: "/modeManage",
        component: () => import("@/views/backstageManage/modeManage/index.vue"),
        name: "modeManage",
        meta: { title: "模块管理", icon: "" },
      },
      {
        path: "/approvalManage",
        component: () =>
          import("@/views/officeManage/approvalManage/index.vue"),
        name: "backapprovalManage",
        meta: { title: "流程设置", icon: "" },
      },
      {
        path: "/userManage",
        component: () => import("@/views/backstageManage/userManage/index.vue"),
        name: "userManage",
        meta: { title: "用户管理", icon: "" },
      },
    ],
  },
];
export default backOfficeRouter;
