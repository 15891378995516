import Layout from "@/layout/index.vue";
// 合作伙伴系统
const partnerManageRouter = [
  {
    path: "/partnerManage",
    component: Layout,
    redirect: "partnerManage",
    hidden: true,
    name: "partnerManage",
    meta: { title: "合作伙伴系统", icon: "" },
    children: [
      {
        path: "/partnerFiles",
        component: () => import("@/views/partnerManage/partnerFiles/index.vue"),
        name: "partnerFiles",
        meta: { title: "伙伴档案", icon: "" },
      },
    ],
  },
];
export default partnerManageRouter;
