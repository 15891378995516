<template>
  <div class="add_container">
    <el-card class="card" style="width: 440px; height: 500px">
      <div class="close" @click="closeDialog">
        <el-icon color="#999999" :size="20">
          <Close />
        </el-icon>
      </div>
      <div class="title">{{ cardTitle }}</div>

      <div class="content">
        <div class="tips">提示信息</div>
        <div class="text">
          {{ cardTips }}
        </div>

        <div class="btn_box">
          <el-button
            plain
            class="select_btn"
            size="large"
            @click="enterpriseTeam"
            style="width: 100%"
          >
            {{ btnText }}
          </el-button>
        </div>
        <div>
          <el-button
            plain
            class="select_btn"
            size="large"
            @click="individualTeam"
            style="width: 100%"
          >
            {{ checkBtnText }}
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script setup>
import { Close } from "@element-plus/icons-vue";
import { ref, defineEmits, onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const { seletDialogProp } = defineProps({
  seletDialogProp: Object,
});
const emits = defineEmits(["closeDialog"]);

const cardTitle = ref("注册成功。下一步");
const cardTips = ref(
  "您可以加入一个或多个团队，也可以创建一个或多个团队，请选择："
);
const btnText = ref("加入团队");
const checkBtnText = ref("创建团队");

onMounted(() => {
  if (seletDialogProp.type === "header") {
    btnText.value = "加入个体团队";
    checkBtnText.value = "加入企业团队";
    cardTitle.value = "加入团队";
    cardTips.value =
      "您可以加入一个或多个团队，也可以创建一个或多个团队，请选择：";
  }
});

const enterpriseTeam = () => {
  if (btnText.value === "加入团队") {
    btnText.value = "加入个体团队";
    checkBtnText.value = "加入企业团队";
    cardTitle.value = "加入团队";
    cardTips.value =
      "您可以加入一个或多个团队，也可以创建一个或多个团队，请选择：";
  } else if (btnText.value === "加入个体团队") {
    router.push("/joinIndividualTeam");
  } else if (btnText.value === "创建个体团队") {
    router.push("/individualTeam");
  }
  // router.push("/joinTeam");
};

const individualTeam = () => {
  if (checkBtnText.value === "创建团队") {
    btnText.value = "创建个体团队";
    checkBtnText.value = "创建企业团队";
    cardTitle.value = "创建团队";
    cardTips.value =
      "您可以创建一个或多个个体团队，也可以创建一个或多个企业团队，请选择：";
  } else if (checkBtnText.value === "加入企业团队") {
    router.push("/joinTeam");
  } else if (checkBtnText.value === "创建企业团队") {
    router.push("/enterpriseTeam");
  }
};

const closeDialog = () => {
  emits("closeDialog");
};
</script>
<style lang="scss" scoped>
.add_container {
  position: fixed;
  top: 50%;
  right: 20%;
  transform: translate(0, -50%);
  z-index: 1000;
  .title {
    padding-top: 30px;
    font-family: Alibaba PuHuiTi 2;
    font-size: 36px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;

    font-feature-settings: "kern" on;
    color: #9e9e9e;
    padding-left: 40px;
  }

  .content {
    padding: 0 50px 0;
    font-family: Alibaba PuHuiTi 2;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;
    .tips {
      color: #06b0b2;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .text {
      margin-bottom: 116px;
      color: #9e9e9e;
    }

    .btn_box {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
    }
    .select_btn {
      height: 50px;
    }
  }
}

.card {
  position: relative;
  .close {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
}
</style>
