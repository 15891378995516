import http from "./request";

// 我的审批
export function getApproveReportsApi(data) {
  return http({
    url: "/api/admin/approve/approveReports",
    method: "post",
    data,
  });
}

// 我的提交
export function getMySubmitApproveApi(data) {
  return http({
    url: "/api/admin/approve/mySubmitApprove",
    method: "post",
    data,
  });
}

// 创办企业-审批
export function tartingBusinessApprovalApi(data) {
  return http({
    url: "/api/admin/systenant/addSysTenantSecondEdition",
    method: "post",
    data,
  });
}

// 根据租户id查询租户信息
export function querySysTenantByIdApi(data) {
  return http({
    url: "/api/admin/systenant/querySysTenantById",
    method: "post",
    data,
  });
}

// 获取当前用户所有租户信息
export function getSysTenantByUserIdApi(data) {
  return http({
    url: "/api/admin/systenant/getSysTenantByUserId",
    method: "get",
    data,
  });
}

// 切换租户
export function switchTenantsApi(params) {
  return http({
    url: "/api/admin/login/switchTenants",
    method: "get",
    params,
  });
}

// 根据id查询加入团队记录
export function queryJoinTenantByIdApi(data) {
  return http({
    url: "/api/admin/applyjointenant/queryJoinTenantById",
    method: "post",
    data,
  });
}

// 获取岗位列表
export function getPostListApi(data) {
  return http({
    url: "/api/admin/syspost/list",
    method: "post",
    data,
  });
}

// 获取职务列表
export function getRoleListApi(data) {
  return http({
    url: "/api/admin/role/rolePage",
    method: "post",
    data,
  });
}

// 加入团队审批
export function actionUserJoinTenantApi(data) {
  return http({
    url: "/api/admin/applyjointenant/actionUserJoinTenant",
    method: "post",
    data,
  });
}
