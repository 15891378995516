<template>
  <div class="edit_container">
    <el-card class="card" style="width: 440px; height: 500px">
      <div class="close" @click="closeDialog">
        <el-icon color="#999999" :size="20">
          <Close />
        </el-icon>
      </div>
      <div class="title">修改您的密码</div>

      <div class="content">
        <el-form
          ref="editForm"
          :model="formData"
          :rules="loginRules"
          autocomplete="on"
          label-position="left"
        >
          <div>
            <el-form-item prop="password">
              <span class="icon">
                <el-icon color="#06B0B2" size="20px"><Lock /></el-icon>
              </span>
              <el-input
                ref="password"
                v-model="formData.password"
                placeholder="请输入原密码"
                name="password"
                size="large"
                :type="oldPasswordType"
                autocomplete="on"
              >
                <template #suffix>
                  <el-icon
                    color="#06B0B2"
                    size="14px"
                    class="show_pwd"
                    @click="showOldPwd"
                  >
                    <Hide v-if="oldPasswordType === 'password'" />
                    <View v-else />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item prop="newPassword">
              <span class="icon">
                <el-icon color="#06B0B2" size="20px"><Lock /></el-icon>
              </span>
              <el-input
                ref="password"
                v-model="formData.newPassword"
                placeholder="请输入8位数字和英文组合的密码"
                name="password"
                size="large"
                :type="passwordType"
                autocomplete="on"
              >
                <template #suffix>
                  <el-icon
                    color="#06B0B2"
                    size="14px"
                    class="show_pwd"
                    @click="showPwd"
                  >
                    <Hide v-if="passwordType === 'password'" />
                    <View v-else />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="rePassword">
              <span class="icon">
                <el-icon color="#D8D8D8" size="20px"><Lock /></el-icon>
              </span>
              <el-input
                ref="password"
                v-model="formData.rePassword"
                placeholder="请确认密码"
                name="password"
                size="large"
                :type="rePasswordType"
                autocomplete="on"
              >
                <template #suffix>
                  <el-icon
                    color="#06B0B2"
                    size="14px"
                    class="show_pwd"
                    @click="showRePwd"
                  >
                    <Hide v-if="rePasswordType === 'password'" />
                    <View v-else />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-card>
    <div class="btn_box">
      <div class="forget_box">
        <el-button type="primary" link></el-button>
        <!-- <el-checkbox v-model="isRemember" label="记住密码" size="large" /> -->
      </div>
      <el-button
        class="select_btn"
        size="large"
        type="primary"
        @click="confirm(editForm)"
        style="width: 100%"
      >
        确 认
      </el-button>
    </div>
  </div>
</template>
<script setup>
import Cookie from "@/uitls/cookie";
import { ElMessage } from "element-plus";
import { editPasswordApi, logoutApi } from "@/api/login";
import { Close, Lock, Hide, View } from "@element-plus/icons-vue";
import { ref, defineEmits, onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const emits = defineEmits(["closeDialog"]);

const editForm = ref(null);
const formData = ref({});
const loginRules = {
  newPassword: [
    { required: true, message: "请输入密码", trigger: "blur" },
    { min: 8, max: 200, message: "密码长度至少8位", trigger: "blur" },
    {
      validator: (rule, value, callback) => {
        if (value == formData.value.password) {
          // 校验两次密码是否一致
          callback(new Error("不能与原密码一致"));
        } else {
          callback();
        }
      },
      trigger: "blur",
    },
  ],
  password: [
    { required: true, message: "请输入密码", trigger: "blur" },
    { min: 8, max: 200, message: "密码长度至少8位", trigger: "blur" },
  ],
  rePassword: [
    { required: true, message: "请确认密码", trigger: "blur" },
    {
      validator: (rule, value, callback) => {
        if (value != formData.value.newPassword) {
          // 校验两次密码是否一致
          callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      },
      trigger: "blur",
    },
  ],
};
const isRemember = ref(false);
const passwordType = ref("password");
const oldPasswordType = ref("password");
const rePasswordType = ref("password");

onMounted(() => {});

const showOldPwd = () => {
  oldPasswordType.value =
    oldPasswordType.value === "password" ? "text" : "password";
};
const showPwd = () => {
  passwordType.value = passwordType.value === "password" ? "text" : "password";
};
const showRePwd = () => {
  rePasswordType.value =
    rePasswordType.value === "password" ? "text" : "password";
};

const confirm = async (editForm) => {
  if (!editForm) return;
  await editForm.validate(async (valid, fields) => {
    if (valid) {
      const res = await editPasswordApi(formData.value);
      if (res.status === 200) {
        ElMessage.success("修改成功，请重新登陆");
        const res = await logoutApi();
        if (res.status === 200) {
          localStorage.clear();
          sessionStorage.clear();
          Cookie.remove("token");
          if (isRemember.value) {
            const param = {
              username: formData.value.username,
              password: formData.value.password,
            };
            localStorage.setItem("loginData", JSON.stringify(param));
          }
          router.push("/login");
        }
      }
    }
  });
};

const closeDialog = () => {
  emits("closeDialog");
};
</script>
<style lang="scss" scoped>
:deep(.el-form-item) {
  margin-bottom: 16px;
}
:deep(.el-input__inner) {
  height: 38px;
}
.edit_container {
  z-index: 9999;
  position: fixed;
  top: 50%;
  right: 20%;
  transform: translate(0, -50%);
  .title {
    margin-bottom: 70px;
    padding-top: 30px;
    font-family: Alibaba PuHuiTi 2;
    font-size: 36px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0em;

    font-feature-settings: "kern" on;
    color: #9e9e9e;
    padding-left: 40px;
  }

  .content {
    padding: 0 50px;

    .tips {
      margin-top: 15px;
      margin-bottom: 20px;
      font-family: Alibaba PuHuiTi 2;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      cursor: pointer;
      letter-spacing: 0em;
      font-feature-settings: "kern" on;
      color: #06b0b2;
    }
    .text {
      padding-left: 60px;
      margin-bottom: 40px;
      font-family: Alibaba PuHuiTi 2;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;
      font-feature-settings: "kern" on;
      color: #9e9e9e;
    }

    .btn_box {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .icon {
      position: absolute;
      left: 20px;
      top: 8px;
      font-size: 18px;
      color: #06b0b2;
      z-index: 999;
    }

    .svg_icon {
      left: 22px;
      top: 5px;
    }

    :deep(.el-input__inner) {
      text-align: right;
    }

    .username {
      width: 100%;
      border-radius: 10px;
      :deep(.el-input__inner) {
        padding-right: 22px;
      }

      :deep(.el-input__wrapper) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      :deep(.el-input-group__prepend) {
        border-radius: 10px;
      }

      .code_btn {
        width: 150px;
        padding-left: 40px;
        color: #06b0b2;
        background-color: #fff;
        height: 100%;
        border: 1px solid #06b0b2;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }
}

.card {
  position: relative;
  .close {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
}
.btn_box {
  position: absolute;
  bottom: 50px;
  width: 100%;
  padding: 0 70px;

  .forget_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 8px;
    margin-bottom: 10px;

    :deep(.el-button > span) {
      font-family: Alibaba PuHuiTi 2;
      font-size: 12px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;
      color: #06b0b2;
    }
  }
}
</style>
