<!-- 功能弹框组件 -->
<template>
  <el-popover
    :width="180"
    trigger="click"
    @show="show"
    @hide="hide"
    popper-class="PopperClass2"
  >
    <template #reference>
      <!-- 编辑类别 -->
      <div
        :class="{ option: true, active: !showColor }"
        @mouseover="show"
        @mouseout="hide"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          fill="none"
          version="1.1"
          width="13.333333969116211"
          height="12"
          viewBox="0 0 13.333333969116211 12"
        >
          <g>
            <path
              d="M8.66667,12L4.66667,12L4.66667,4.66667L8.66667,4.66667L8.66667,12ZM10,12L10,4.66667L13.3333,4.66667L13.3333,11.3333C13.3333,11.7015,13.0349,12,12.6667,12L10,12ZM3.33333,12L0.666667,12C0.298477,12,0,11.7015,0,11.3333L0,4.66667L3.33333,4.66667L3.33333,12ZM13.3333,3.33333L0,3.33333L0,0.666667C0,0.298477,0.298477,0,0.666667,0L12.6667,0C13.0349,0,13.3333,0.298477,13.3333,0.666667L13.3333,3.33333Z"
              :fill="Color"
              fill-opacity="1"
            />
          </g>
        </svg>
      </div>
    </template>
    <!-- 弹框标题 -->
    <div class="title">{{ title }}</div>
    <!-- 弹框内容 -->
    <el-checkbox
      class="Storecheckboxbgc"
      :label="item.prop"
      v-for="(item, index) in headerData"
      :key="index"
      v-model="item.checked"
      >{{ item.label }}</el-checkbox
    >
  </el-popover>
</template>
<script setup>
import { ref, defineProps } from "vue";
const showColor = ref(true);
const Color = ref("#666666");
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  headerData: {
    type: Array,
    required: true,
  },
});

// 弹框显示时触发
const show = () => {
  showColor.value = false;
  Color.value = "#06B0B2";
};
// 弹框隐藏时触发
const hide = () => {
  showColor.value = true;
  Color.value = "#666666";
};
</script>
<style lang="scss" scoped>
.option {
  width: 34px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #d1d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.active {
    border: 1px solid #06b0b2;
  }
}

.title {
  font-size: 12px;
  color: #999999;
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
}
.PopperClass2 {
  max-height: 400px !important;
  overflow: auto !important;
  padding: 0px !important;
}
// 禁用多选框的样式
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner) {
  background-color: #e1e1e1 !important;
  border-color: #e1e1e1 !important;
}

:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after) {
  border-color: #fff !important;
}

.Storecheckboxbgc {
  width: 100%;
  margin-right: 0px;
  padding-left: 20px;
}

.Storecheckboxbgc:hover {
  background-color: rgba(6, 176, 178, 0.1);
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #0fc6c2 !important;
  border-color: #0fc6c2 !important;
}

:deep(.el-checkbox__label) {
  color: #999999 !important;
  font-size: 12px !important;
}
</style>
