import Layout from "@/layout/index.vue";
// 装修工程系统
const decorationWorksSystemRouter = [
  {
    path: "/decorationWorksSystem",
    component: Layout,
    redirect: "decorationWorksSystem",
    hidden: true,
    name: "decorationWorksSystem",
    meta: { title: "装修工程系统", icon: "" },
    children: [
      {
        path: "/decorationProjectProposal",
        component: () =>
          import(
            "@/views/decorationWorksSystem/decorationProjectProposal/index.vue"
          ),
        name: "decorationProjectProposal",
        meta: { title: "装修立项", icon: "" },
      },
    ],
  },
];
export default decorationWorksSystemRouter;
