<!-- 复用组件 标题 -->
<template>
  <div class="box-header">
    <!-- 左上标题 -->
    <div class="box-left">
      <div class="box-left-it">
        <span>
          <!-- 侧边蓝条 -->
          <span class="sign" v-if="titleShow"></span>
          <!-- 标题 -->
          <span
            :class="{
              title: isLittle,
              little: !isLittle,
              gradual: isGradual && isLittle,
            }"
            ><span style="position: relative; z-index: 999">{{
              props.title
            }}</span></span
          >
        </span>
        <!-- 排行分类 -->
        <span v-if="classify" class="rowClass">
          <span
            v-for="(it, index) in classify"
            :class="{ active: rowCut === index + 1 }"
            @click="changeRowCut(index + 1)"
            >{{ it }}</span
          >
        </span>
      </div>
      <!-- 年份切换 -->
      <div class="yearBox" v-if="year">
        <el-icon @click="alterYear(1)">
          <!-- <CaretLeft /> -->
        </el-icon>
        <span>{{ year.year }}</span>
        <el-icon :class="{ color: forbidden }" @click="alterYear(2)">
          <!-- <CaretRight /> -->
        </el-icon>
      </div>
      <!-- 年月切换 -->
      <div v-if="date" class="dateBox">
        <span :class="{ active: years === 1 }" @click="changeYears(1)">月</span>
        <span :class="{ active: years === 2 }" @click="changeYears(2)">年</span>
      </div>
    </div>

    
  </div>
</template>

<script setup>
import { CaretLeft, CaretRight } from "@element-plus/icons-vue";
import { ref, defineEmits } from "vue";
// 接收父传值
const props = defineProps({
  // 渐变三角形
  isGradual: {
    type: Boolean,
    default: true,
  },
  // 区分年月切换
  date: {
    type: String,
  },
  // 分类数据
  classify: {
    type: Array,
  },
  // 区分分类
  classifyStr: {
    type: String,
  },
  // 年份数据
  year: {
    type: Object,
  },
  // 区块标题
  title: {
    type: String,
    required: true,
  },
  // 侧边蓝条显示隐藏
  titleShow: {
    type: Boolean,
    default: true,
  },
  forbidden: {
    type: Boolean,
    default: false,
  },
  // 小标题
  isLittle: {
    type: Boolean,
    default: true,
  },
  // 弹框标题
  // popoverTitle: {
  //     type: String,
  // },
  // 显示隐藏
  // more: {
  //     type: Boolean,
  //     default: true
  // },
  // 弹框位置
  // alignment: {
  //     type: String,
  //     default: 'left-start'
  // }
});
// const active = ref(0)

const emit = defineEmits();

// 切换年份
const alterYear = (num) => {
  if (props.forbidden && num === 2) {
    return;
  }
  emit("changeYear", { num: num, year: props.year });
};
// 排行切换
const rowCut = ref(1);
const changeRowCut = (num) => {
  rowCut.value = num;
  emit("changeRowCut", { num: num, str: props.classifyStr });
};
// 年月切换
const years = ref(1);
const changeYears = (num) => {
  years.value = num;
  emit("changeYears", { num: num, date: props.date });
};
</script>

<style lang="scss" scoped>
// 侧边蓝条
.sign {
  display: inline-block;
  background: #0fc6c2;
  width: 0.2439vh;
  height: 1.7073vh;
  vertical-align: middle;
}

// 年月切换
.dateBox {
  position: relative;
  bottom: 0.3652vh;
  cursor: pointer;
}

.color {
  color: #999999;
}

// 切换样式类
.active {
  color: #0fc6c2 !important;
}

// 大盒子
.box-header {
  .box-left {
    display: flex;
    justify-content: space-between;

    .box-left-it {
      display: flex;
    }
    // 渐变三角形
    .gradual::after {
      content: "";
      position: absolute;
      left: 0px;
      top: 10px;
      width: 100%;
      height: 12px;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 5%, #a4f6f6);
    }

    // 标题
    .title {
      position: relative;
      margin-left: 0.6094vh;
      vertical-align: middle;
      font-weight: 400;
      color: #666666;
      font-size: 16px;
    }
    .little {
      margin-left: 0.6094vh;
      vertical-align: middle;
      font-weight: 400;
      color: #666666;
      font-size: 1.4634vh;
    }

    // 年度切换
    .yearBox {
      width: 9.7568vh;
      height: 2.9268vh;
      border-radius: 0.4882vh;
      font-size: 1.4628vh;
      color: #06b0b2;
      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: -1.5854vh;
      margin-bottom: 0.9763vh;
      cursor: pointer;
    }

    // 排行分类
    .rowClass {
      cursor: pointer;
      height: 2.9268vh;
      display: flex;
      font-size: 1.4628vh;
      height: 2.9268vh;
      position: relative;
      bottom: 0.3652vh;
      left: 1.2188vh;

      span {
        border: 0.1212vh solid #eeeeee;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #bbbbbb;
        padding: 0 1.2195vh 0 1.2195vh;

        &.active {
          border: 0.1212vh solid #06b0b2;
        }
      }

      span:nth-child(1) {
        border-radius: 0.4882vh 0 0 0.4882vh;
      }

      span:last-child {
        border-radius: 0 0.4882vh 0.4882vh 0;
      }
    }

    .dateBox {
      display: flex;

      span {
        width: 4.8784vh;
        height: 2.9268vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4628vh;
        border: 0.1212vh solid #eeeeee;
        color: #bbbbbb;

        &:nth-child(1) {
          border-radius: 0.4882vh 0 0 0.4882vh;
        }

        &:nth-child(2) {
          border-radius: 0 0.4882vh 0.4882vh 0;
        }

        &.active {
          color: #06b0b2;
          border: 0.1212vh solid #06b0b2;
        }
      }
    }
  }

  // // list数据样式
  // .tag {
  //     font-size: 1.2188vh;
  //     vertical-align: bottom;
  //     color: #7e7979;
  //     margin-left: 1.8299vh;
  //     cursor: pointer;
  // }
  // // 弹框图标样式
  // .box-right {
  //     .el-icon {
  //         transform: rotate(90deg);
  //         cursor: pointer;
  //     }
  // }
}

// // 弹框标题
// .popover-header {
//     .popover-title {
//         margin-left: 0.3652vh;
//     }
// }
// // 弹框内容
// .popover-content {
//     padding: 1.8299vh 5.4878vh 2.4393vh 3.6579vh;
// }
</style>
