import Layout from "@/layout/index.vue";
// 销售管理系统
const salesManageRouter = [
  {
    path: "/salesManage",
    component: Layout,
    redirect: "salesManage",
    hidden: true,
    name: "salesManage",
    meta: { title: "销售管理系统", icon: "" },
    children: [
      {
        path: "/salesProcess",
        component: () => import("@/views/salesManage/salesProcess/index.vue"),
        name: "salesProcess",
        meta: { title: "销售过程", icon: "" },
      },
    ],
  },
];
export default salesManageRouter;
