import http from "./request";

// 通过身份证图片识别身份证+年月日
export function discernCodeApi(data) {
  return http({
    url: "/api/admin/systenant/idCardOcr",
    method: "post",
    headers: { "Content-Type": "application/json" },
    data,
  });
}

// 通用图片上传 后台data返回 文件名
export function imgUpLoadApi(data) {
  return http({
    url: "/api/admin/fileUtil/uploadFile",
    method: "post",
    data,
  });
}

// 百度识别code （营业执照）
export function extractCodeApi(data) {
  return http({
    url: "/api/admin/systenant/businessLicense",
    method: "post",
    headers: { "Content-Type": "application/json" },
    data,
  });
}

// 根据字段选项的key查询
export function queryByFieldKeyApi(data) {
  return http({
    url: "/api/admin/sysmenuListFieldDict/queryByFieldKey",
    method: "post",
    data,
  });
}

// 根据字段选项的key查询-多key
export function queryByFieldKeysApi(data) {
  return http({
    url: "/api/admin/sysmenuListFieldDict/queryByFieldKeys",
    method: "post",
    data,
  });
}

// 根据字段选项的key查询-list子级
export function queryByFieldKeyAndChildApi(data) {
  return http({
    url: "/api/admin/sysmenuListFieldDict/queryByFieldKeyAndChild",
    method: "post",
    data,
  });
}

// 同意按钮
export function completeTaskApi(data) {
  return http({
    url: "/api/oa/process/agree",
    method: "post",
    data,
  });
}

// 驳回任务
export function rejectTaskApi(data) {
  return http({
    url: "/api/oa/process/refuse",
    method: "post",
    data,
  });
}

// 根据表单ID查询任务ID
export function findTaskIdByFormIdApi(params) {
  return http({
    url: "/api/oa/task/findTaskIdByFormId",
    method: "get",
    params,
  });
}

// 根据表单ID查询审批记录
export function findApprovalByFormIdApi(params) {
  return http({
    url: "/api/oa/task/findApprovalByFormId",
    method: "get",
    params,
  });
}

// 获取自己租户下的收货用户
export function getTenantUserNameApi(params) {
  return http({
    url: "/api/admin/sysuser/getTenantUserName",
    method: "get",
    params,
  });
}

// 流程查询
export function queryBagByMenuIdApi(params) {
  return http({
    url: "/api/admin/appBag/queryBagByMenuId",
    method: "get",
    params,
  });
}

// 查询租户选择的功能包
export function getAppBagTenantApi(params) {
  return http({
    url: "/api/admin/appBag/getAppBagTenant",
    method: "get",
    params,
  });
}
