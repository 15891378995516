<template>
  <div>
    <el-container class="layout_container">
      <el-aside
        :style="{ width: !isCollapse ? '200px' : '54px', overflow: 'hidden' }"
      >
        <div class="aside_box">
          <div class="logo" v-if="!isCollapse" @click="toHome">
            <img src="@/assets/logo.png" alt="" />
            <div class="title">让管理更轻松</div>
          </div>
          <div class="logo" v-else @click="toHome">
            <img
              src="@/assets/logo.png"
              alt=""
              style="width: 32px; height: 32px; border-radius: 16px"
            />
          </div>

          <div class="menu_box">
            <!-- 分割线 -->
            <div class="segmentation">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                fill="none"
                version="1.1"
                width="168"
                height="1px"
                viewBox="0 0 168 1"
              >
                <g>
                  <path
                    d="M0,0.25L0,1L2,1L2,0L0,0L0,0.25ZM6,0L6,1L10,1L10,0L6,0ZM14,0L14,1L18,1L18,0L14,0ZM22,0L22,1L26,1L26,0L22,0ZM30,0L30,1L34,1L34,0L30,0ZM38,0L38,1L42,1L42,0L38,0ZM46,0L46,1L50,1L50,0L46,0ZM54,0L54,1L58,1L58,0L54,0ZM62,0L62,1L66,1L66,0L62,0ZM70,0L70,1L74,1L74,0L70,0ZM78,0L78,1L82,1L82,0L78,0ZM86,0L86,1L90,1L90,0L86,0ZM94,0L94,1L98,1L98,0L94,0ZM102,0L102,1L106,1L106,0L102,0ZM110,0L110,1L114,1L114,0L110,0ZM118,0L118,1L122,1L122,0L118,0ZM126,0L126,1L130,1L130,0L126,0ZM134,0L134,1L138,1L138,0L134,0ZM142,0L142,1L146,1L146,0L142,0ZM150,0L150,1L154,1L154,0L150,0ZM158,0L158,1L162,1L162,0L158,0ZM166,0L166,1L168,1L168,0L166,0Z"
                    fill-rule="evenodd"
                    fill="#EEEEEE"
                    fill-opacity="1"
                  />
                </g>
              </svg>
            </div>
            <el-scrollbar wrap-class="scrollbar-wrapper">
              <sidebarMenu :isCollapse="isCollapse" />
            </el-scrollbar>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="none"
            version="1.1"
            width="168"
            height="1"
            viewBox="0 0 168 1"
          >
            <g>
              <path
                d="M0,0.25L0,1L2,1L2,0L0,0L0,0.25ZM6,0L6,1L10,1L10,0L6,0ZM14,0L14,1L18,1L18,0L14,0ZM22,0L22,1L26,1L26,0L22,0ZM30,0L30,1L34,1L34,0L30,0ZM38,0L38,1L42,1L42,0L38,0ZM46,0L46,1L50,1L50,0L46,0ZM54,0L54,1L58,1L58,0L54,0ZM62,0L62,1L66,1L66,0L62,0ZM70,0L70,1L74,1L74,0L70,0ZM78,0L78,1L82,1L82,0L78,0ZM86,0L86,1L90,1L90,0L86,0ZM94,0L94,1L98,1L98,0L94,0ZM102,0L102,1L106,1L106,0L102,0ZM110,0L110,1L114,1L114,0L110,0ZM118,0L118,1L122,1L122,0L118,0ZM126,0L126,1L130,1L130,0L126,0ZM134,0L134,1L138,1L138,0L134,0ZM142,0L142,1L146,1L146,0L142,0ZM150,0L150,1L154,1L154,0L150,0ZM158,0L158,1L162,1L162,0L158,0ZM166,0L166,1L168,1L168,0L166,0Z"
                fill-rule="evenodd"
                fill="#EEEEEE"
                fill-opacity="1"
              />
            </g>
          </svg>
          <div
            class="fold"
            :style="{ width: !isCollapse ? '200px' : '54px' }"
            @click="toggleSideBar"
            @mouseover="mouseoverColor"
            @mouseout="mouseoutColor"
          >
            <svg
              v-show="!hoverColor"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              fill="none"
              version="1.1"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <defs>
                <clipPath id="master_svg0_66_21176">
                  <rect x="0" y="0" width="16" height="16" rx="0" />
                </clipPath>
              </defs>
              <g clip-path="url(#master_svg0_66_21176)">
                <g>
                  <path
                    d="M14.000035876464844,11.99996818359375L14.000035876464844,13.33333818359375L2.0000028764648436,13.33333818359375L2.0000028764648436,11.99996818359375L14.000035876464844,11.99996818359375ZM4.633335876464844,2.36663818359375L4.633335876464844,8.96663818359375L1.3333358764648438,5.66663818359375L4.633335876464844,2.36663818359375ZM14.000035876464844,7.33330818359375L14.000035876464844,8.66663818359375L8.000005876464844,8.66663818359375L8.000005876464844,7.33330818359375L14.000035876464844,7.33330818359375ZM14.000035876464844,2.66663818359375L14.000035876464844,3.99996818359375L8.000005876464844,3.99996818359375L8.000005876464844,2.66663818359375L14.000035876464844,2.66663818359375Z"
                    fill="#D4D7D6"
                    fill-opacity="1"
                  />
                </g>
              </g>
            </svg>
            <svg
              v-show="hoverColor"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              fill="none"
              version="1.1"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <defs>
                <clipPath id="master_svg0_66_20805">
                  <rect x="0" y="0" width="16" height="16" rx="0" />
                </clipPath>
              </defs>
              <g clip-path="url(#master_svg0_66_20805)">
                <g>
                  <path
                    d="M14.000035876464844,11.99996818359375L14.000035876464844,13.33333818359375L2.0000028764648436,13.33333818359375L2.0000028764648436,11.99996818359375L14.000035876464844,11.99996818359375ZM4.633335876464844,2.36663818359375L4.633335876464844,8.96663818359375L1.3333358764648438,5.66663818359375L4.633335876464844,2.36663818359375ZM14.000035876464844,7.33330818359375L14.000035876464844,8.66663818359375L8.000005876464844,8.66663818359375L8.000005876464844,7.33330818359375L14.000035876464844,7.33330818359375ZM14.000035876464844,2.66663818359375L14.000035876464844,3.99996818359375L8.000005876464844,3.99996818359375L8.000005876464844,2.66663818359375L14.000035876464844,2.66663818359375Z"
                    fill="#06B0B2"
                    fill-opacity="0.5"
                  />
                </g>
              </g>
            </svg>
            <div class="fold_text" :class="{ textColor: hoverColor }">
              {{ isCollapse ? "展开" : "收起" }}
            </div>
          </div>
        </div>
      </el-aside>
      <el-container>
        <el-header class="header">
          <Header />
        </el-header>
        <el-main class="main_container">
          <router-view :key="$route.fullPath" />
        </el-main>
        <el-footer class="footer">
          <Footer />
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>
<script setup>
import { ref } from "vue";
import Header from "./header.vue";
import Footer from "./footer.vue";
import sidebarMenu from "./sidebarMenu.vue";

const isCollapse = ref(false);
const hoverColor = ref(false);

const toHome = () => {
  window.location.href = "/";
  localStorage.setItem("activeMenu", "");
  localStorage.setItem(
    "activeMenuId",
    JSON.parse(localStorage.getItem("menuTree"))[0].id
  );
};

const toggleSideBar = () => {
  isCollapse.value = !isCollapse.value;
};

const mouseoverColor = () => {
  hoverColor.value = true;
};

const mouseoutColor = () => {
  hoverColor.value = false;
};
</script>
<style lang="scss" scoped>
.layout_container {
  height: 100vh;
}
.aside_box {
  height: 100vh;
  text-align: center;
  // display: flex;
  // flex-direction: column;
  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px;
    cursor: pointer;
    padding: 20px;
    padding-bottom: 0;
    img {
      width: 68px;
      height: 68px;
    }

    .title {
      margin-top: 12px;
      font-size: 16px;
      color: #06b0b2;
    }
  }

  .menu_box {
    height: calc(100vh - 193px);
    flex: 1;
    overflow: hidden;

    .segmentation {
      text-align: center;
    }
  }

  .fold {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;

    .fold_text {
      padding-left: 6px;
      font-size: 12px;
      color: #d4d7d6;
    }
    &:hover {
      cursor: pointer;
      .fold_text {
        color: #06b0b2;
      }
    }
  }
}

.header {
  padding: 0 16px;
  height: 72px;
}

.main_container {
  background-color: rgb(247, 250, 250);
  overflow: hidden;
  padding: 8px;
  padding-right: 16px !important;
}

.footer {
  height: 50px;
  padding: 16px;
}

.textColor {
  color: #06b0b2 !important;
  opacity: 0.5;
}
</style>
