<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>

<script>
import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";

import zhCn from "element-plus/es/locale/lang/zh-cn";

export default defineComponent({
  components: {
    ElConfigProvider,
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
});
</script>

<style lang="scss">
@import "~@/style/theme_1.scss";

* {
  font-family: Alibaba PuHuiTi 2 !important;
}

:root {
  --el-border-color: #eeeeee;
  //--el-border-color-light: #e4e7ed;
  //--el-border-color-lighter: #ebeef5;
  //--el-border-color-extra-light: #f2f6fc;
  //--el-border-color-dark: #d4d7de;
  //--el-border-color-darker:#eeeeee
}
</style>
