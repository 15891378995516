import Layout from "@/layout/index.vue";
// 平台人事 管理
const personnelMattersRouter = [
  {
    path: "/personnelManage",
    component: Layout,
    redirect: "personnelManage",
    hidden: true,
    name: "personnelManage",
    meta: { title: "人事管理系统", icon: "safety" },
    children: [
      // 字段字典
      {
        path: "/fieldDictionary",
        component: () =>
          import("@/views/personnelManage/personnel/fieldDictionary/index.vue"),
        name: "fieldDictionary",
        meta: { title: "字段字典", icon: "gateway" },
      },
      // 人事字典
      {
        path: "/personnelDictionary",
        component: () =>
          import(
            "@/views/personnelManage/personnel/personnelDictionary/index.vue"
          ),
        name: "personnelDictionary",
        meta: { title: "人事字典", icon: "gateway" },
      },
      {
        path: "/employeeFiles",
        component: () =>
          import("@/views/personnelManage/employeeFiles/index.vue"),
        name: "employeeFiles",
        meta: { title: "员工档案", icon: "gateway" },
      },
    ],
  },
];
export default personnelMattersRouter;
