import Cookies from "js-cookie";

const Cookie = {
  set: function (key, value, options) {
    Cookies.set(key, value, options);
  },
  get: function (key) {
    return Cookies.get(key);
  },
  remove: function (key, options) {
    Cookies.remove(key, options);
  },
};

export default Cookie;
