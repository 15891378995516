const joinTeamRouter = [
  {
    path: "/joinTeam",
    name: "JoinTeam",
    component: () => import("@/views/joinTeam/index"),
    hidden: true,
    children: [
      {
        path: "/joinTeam",
        component: () =>
          import("@/views/joinTeam/comoponets/teamOperation.vue"),
        meta: { title: "加入企业团队", icon: "gateway" },
      },
      {
        path: "/selectionMethod",
        component: () =>
          import("@/views/joinTeam/comoponets/selectionMethod.vue"),
        meta: { title: "加入/创建团队", icon: "gateway" },
      },
      {
        path: "/joinIndividualTeam",
        component: () =>
          import("@/views/joinTeam/comoponets/joinIndividualTeam.vue"),
        meta: { title: "加入个人团队", icon: "gateway" },
      },
      {
        path: "/addInfo",
        name: "AddInfo",
        component: () => import("@/views/joinTeam/comoponets/addInfo.vue"),
        meta: { title: "完善个人信息", icon: "gateway" },
      },
      {
        path: "/manualInput",
        component: () => import("@/views/joinTeam/comoponets/manualInput.vue"),
        meta: { title: "手动输入", icon: "gateway" },
      },
      {
        path: "/identify",
        component: () => import("@/views/joinTeam/comoponets/identify.vue"),
        meta: { title: "身份证识别", icon: "gateway" },
      },
      {
        path: "/createTeam",
        component: () => import("@/views/joinTeam/comoponets/createTeam.vue"),
        meta: { title: "创建团队", icon: "gateway" },
      },
      {
        path: "/enterpriseTeam",
        component: () =>
          import("@/views/joinTeam/comoponets/enterpriseTeam.vue"),
        meta: { title: "创建企业团队", icon: "gateway" },
      },
      {
        path: "/individualTeam",
        component: () =>
          import("@/views/joinTeam/comoponets/individualTeam.vue"),
        meta: { title: "创建个人团队", icon: "gateway" },
      },
      {
        path: "/inCheck",
        component: () => import("@/views/joinTeam/comoponets/inCheck.vue"),
        meta: { title: "创建个人团队选择模块", icon: "gateway" },
      },
      {
        path: "/individualSubmit",
        component: () =>
          import("@/views/joinTeam/comoponets/individualSubmit.vue"),
        meta: { title: "创建个人团队提交", icon: "gateway" },
      },
      {
        path: "/enterpriseInfo",
        component: () =>
          import("@/views/joinTeam/comoponets/enterpriseInfo.vue"),
        meta: { title: "企业信息完善", icon: "gateway" },
      },
      {
        path: "/corporateInfo",
        component: () =>
          import("@/views/joinTeam/comoponets/corporateInfo.vue"),
        meta: { title: "企业信息完善（法人委托书）", icon: "gateway" },
      },
      {
        path: "/checkModel",
        component: () => import("@/views/joinTeam/comoponets/checkModel.vue"),
        meta: { title: "选择模块", icon: "gateway" },
      },
      {
        path: "/share",
        component: () => import("@/views/joinTeam/comoponets/share.vue"),
        meta: { title: "创建团队分享", icon: "gateway" },
      },
      {
        path: "/submit",
        name: "Submit",
        component: () => import("@/views/joinTeam/comoponets/submit.vue"),
        meta: { title: "已提交", icon: "gateway" },
      },
    ],
  },
];
export default joinTeamRouter;
