// 年月日
import {
  queryByFieldKeyApi,
  queryByFieldKeysApi,
  queryByFieldKeyAndChildApi,
  queryBagByMenuIdApi,
} from "@/api/base";
export function timestampToDate(timestamp) {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// 年月
export function getYearMonth(timestamp) {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}`;
}

// 年月日 时分秒
export function getYearMonthTime(timestamp) {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

// 获取字典数据
export async function queryByFieldKey(fieldKey, status) {
  const param = {
    fieldKey,
    status,
  };
  const res = await queryByFieldKeyApi(param);
  if (res.status === 200) {
    const data = res.data.map((item) => {
      return {
        value: item.id,
        label: item.dictName,
      };
    });
    return data;
  }
}

// 获取多个字典数据
export async function queryByFieldKeys(fieldKeys) {
  const res = await queryByFieldKeysApi(fieldKeys);
  if (res.status === 200) {
    const data = {};
    fieldKeys.forEach((item) => {
      data[item] = res.data[item].map((field) => {
        if (item === "PAYMENT_METHOD_YES_ADVANCE") {
          return {
            label: field.dictName,
            value: field.dictName,
            dictCode: field.dictCode,
          };
        }
        return {
          label: field.dictName,
          value: field.id,
          dictCode: field.dictCode,
        };
      });
    });
    return data;
  }
}

// 获取多级字典数据
export async function queryByFieldKeyAndChild(fieldKey) {
  const param = {
    fieldKey,
  };
  const res = await queryByFieldKeyAndChildApi(param);
  if (res.status === 200) {
    return res.data;
  }
}

// 流程查询
export async function queryProcess(id) {
  const param = {
    id,
  };
  const res = await queryBagByMenuIdApi(param);
  if (res.status === 200) {
    return res.data;
  }
}
