import Layout from "@/layout/index.vue";
// 采购管理系统
const procureManageRouter = [
  {
    path: "/procureManage",
    component: Layout,
    redirect: "procureManage",
    hidden: true,
    name: "procureManage",
    meta: { title: "采购管理系统", icon: "" },
    children: [
      {
        path: "/procureDictionary",
        component: () =>
          import("@/views/procureManage/diction/procureDictionary/index.vue"),
        name: "procureDictionary",
        meta: { title: "字段字典", icon: "" },
      },
      {
        path: "/materialDictionary",
        component: () =>
          import("@/views/procureManage/diction/materialDictionary/index.vue"),
        name: "materialDictionary",
        meta: { title: "物料字典", icon: "" },
      },
      {
        path: "/procureProcess",
        component: () =>
          import("@/views/procureManage/procureProcess/index.vue"),
        name: "procureProcess",
        meta: { title: "采购过程", icon: "" },
      },
      {
        path: "/procureTemplate",
        component: () =>
          import(
            "@/views/procureManage/templateManage/procureTemplate/index.vue"
          ),
        name: "procureTemplate",
        meta: { title: "采购模版", icon: "" },
      },
    ],
  },
];
export default procureManageRouter;
