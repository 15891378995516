import { createApp } from "vue";
import router from "./router";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
//引入pinia
import { createPinia } from "pinia";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css";

import "./api/request.js";
import "./style/element-variables.scss";
import "./style/base.css";
import "./style/index.scss"; // global css
// import "./style/element.scss";

import globalTabel from "./components/globalTabel";
import Model from "@/components/model/index.vue";
import FormItem from "@/components/model/formItem.vue";
import EditTabel from "@/components/editTabel/index.vue";
import globalDialog from "@/components/globalDialog.vue";

// 导入自定义指令
import * as directives from "@/uitls/btnPermission";

import App from "./App.vue";

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router); //注册路由
app.use(ElementPlus, {
  locale: zhCn,
});
app.component("globalTabel", globalTabel); //注册全局表格组件
app.component("Model", Model); //注册模型组件
app.component("FormItem", FormItem); //注册表单项组件
app.component("EditTabel", EditTabel); //注册编辑表格组件
app.component("globalDialog", globalDialog); //注册全局弹窗组件
app.mount("#app");

//挂载自定义指令
Object.keys(directives).forEach((key) => {
  app.directive(key, directives[key]);
});

// 防抖函数,解决element-plus页面重置报ResizeObserver loop completed with undelivered notifications的错误
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
