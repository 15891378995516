import Layout from "@/layout/index.vue";
// 财务管理系统
const financialManageRouter = [
  {
    path: "/financialManage",
    component: Layout,
    redirect: "financialManage",
    hidden: true,
    name: "financialManage",
    meta: { title: "财务管理系统" },
    children: [
      {
        path: "/reimburseManage",
        component: () =>
          import("@/views/financialManage/reimburseManage/index.vue"),
        name: "reimburseManage",
        meta: { title: "报销管理" },
      },
      {
        path: "/financialPrepay",
        component: () =>
          import("@/views/financialManage/payInAdvanceManage/index.vue"),
        name: "payInAdvanceManage",
        meta: { title: "预付管理" },
      },
      {
        path: "/financialPayables",
        component: () =>
          import("@/views/financialManage/financialPayables/index.vue"),
        name: "financialPayables",
        meta: { title: "应付管理" },
      },
    ],
  },
];
export default financialManageRouter;
